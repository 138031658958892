figure.zoom {
    margin: 0px !important;
    position: relative;
    overflow: hidden;
    cursor: zoom-in;
  }
  figure.zoom img:hover {
    opacity: 0;
  }
  figure.zoom img {
    transition: opacity 0.5s;
    display: block;
    width: 100%;
    height: 100%;
  }