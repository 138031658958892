select {
    padding: 7px;
    background: #444;
    color: #fff;
    border: none;
}

.log-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
}

#api-down {
    display: block;
    width: fit-content;
    text-align: center;
    margin: 40px auto 0 auto;
}

#api-down h1 {
    color: #8b0404;
    font-size: 44px;
}

#header {
    position: sticky;
    top: 0;
    padding: 10px 25px;
}

.log {
    padding: 8px 18px;
    border-bottom: 1px solid #323232;
}

.log-payload {
    padding-top: 4px;
}

.log-debug-prefix {
    color: gray;
}

.log-info-prefix {
    color: lightblue;
}

.log-warn-prefix {
    color: #e5b567;
}

.log-error-prefix {
    color: #e56767;
}

.log-fatal-prefix {
    color: #ff0000;
}