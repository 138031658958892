#app {
    background-color: #f0f4f7;
    padding-top: 20px;
    min-height: 100vh;
}

.standard-divider {
    display: block;
    height: 32px;
    width: 100%;
}

.big-block-desktop {
    width: calc(60% + 300px);
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 14px;
}

.big-block-mobile {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 14px;
}

.major-header {
    color: rgba(0, 0, 0, .87);
    padding-top: 14px;
    padding-bottom: 22px;
    font-weight: 500;
    font-size: 42px;
}

.big-block-margin {
    margin-top: 32px;
}

.internal-block {
    width: calc(84% - 40px);
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    padding-bottom: 12px;
}

.sort-amount-down {
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-right: 60px solid blue;
}

.copyable {
    cursor: pointer;
    text-decoration: underline;
}