.custom-input-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the input */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  min-width: 280px; /* Minimum width for the tooltip */
  white-space: pre-wrap; /* Preserve whitespace and new lines */
}

.custom-input-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
  